import * as React from 'react'
import { Repeater, Text, types } from 'react-bricks/frontend'
import Section from '../../components/Section'
import { BackgroundProps, sideBackgroundProps } from '../fixedSideEditProps'
import { blockNames } from '../blockNames'

interface ThumbnailsProps extends BackgroundProps {}
const Thumbnails: types.Brick<ThumbnailsProps> = ({ bg }) => {
  return (
    <Section bg={bg} className="max-w-7xl mx-auto w-full pt-16 px-6">
      <Text
        placeholder="Compravendita immobiliare"
        propName="title"
        renderBlock={({ children }) => (
          <h2 className="text-center mb-12 text-2xl font-bold text-gray-900 dark:text-gray-100">{children}</h2>
        )}
      />

      <div className="flex flex-wrap justify-center items-center">
        <Repeater
          propName="thumbnail"
          renderWrapper={(items) => (
            <div className="flex flex-wrap justify-between mx-auto px-6 md:px-0 md:w-full max-w-5xl md:-mx-8">
              <div className="grid md:grid-cols-3">{items}</div>
            </div>
          )}
        />
      </div>
    </Section>
  )
}
Thumbnails.schema = {
  name: blockNames.Thumbnails,
  label: 'Thumbnails',
  playgroundLinkLabel: 'View source code on Github',
  playgroundLinkUrl: 'https://github.com/ReactBricks/react-bricks-ui/blob/master/src/website/Thumbnails/Thumbnails.tsx',

  getDefaultProps: () => ({
    bg: { color: '#ffffff', className: 'bg-white' },
    title: 'Compravendita immobiliare',
    thumbnail: [
      {
        title: 'Valutazione e perizie immobiliari',
        text: 'Vi offriamo una valutazione seria ed aggiornata all’attuale andamento del mercato immobiliare.',
        imageOrIcon: 'icon',
        image: {
          src: '/icon-home.svg',
          placeholderSrc: '/icon-home.svg',
          srcSet: '',
        },
      },
      {
        title: 'Pubblicità sui portali immobiliari',
        text: 'Affidandoci la vendita del vostro immobile vi garantiamo importanti investimenti pubblicitari.',
        imageOrIcon: 'icon',
        image: {
          src: '/icon-megaphone.svg',
          placeholderSrc: '/icon-megaphone.svg',
          srcSet: '',
        },
      },
      {
        title: 'Servizio fotografico',
        text: 'Vi offriamo un servizio fotografico professionale, interno ed esterno, dell’immobile posto in vendita.',
        imageOrIcon: 'image',
        image: {
          src: 'https://images.reactbricks.com/original/193ab040-1fc7-47b5-8ca0-c2176be578a6.svg',
          placeholderSrc: 'https://images.reactbricks.com/original/193ab040-1fc7-47b5-8ca0-c2176be578a6.svg',
          srcSet: '',
        },
      },
    ],
  }),
  repeaterItems: [
    {
      name: 'thumbnail',
      itemType: blockNames.Thumbnail,
      itemLabel: 'Thumbnail',
      min: 0,
      max: 6,
    },
  ],
  sideEditProps: [sideBackgroundProps],
}
export default Thumbnails
