import React from 'react'
import { Text, RichText, Image, types } from 'react-bricks/frontend'
import Section from '../../components/Section'
import { BackgroundProps, sideBackgroundProps } from '../fixedSideEditProps'
import { blockNames } from '../blockNames'

interface TextImageProps extends BackgroundProps {}

const TextImage: types.Brick<TextImageProps> = ({ bg }) => {
  return (
    <Section
      bg={bg}
      className="max-w-7xl mx-auto px-6 py-12 flex flex-col space-y-12 md:space-y-0 md:space-x-12 md:flex-row"
    >
      <div className="flex-[2]">
        <Text
          propName="title"
          placeholder="Title..."
          renderBlock={({ children }) => (
            <h2 className="mx-auto mb-10 text-2xl font-bold text-gray-900 dark:text-gray-100">{children}</h2>
          )}
        />

        <div className="text-gray-600 dark:text-gray-300">
          <RichText
            propName="paragraph"
            placeholder="Type a paragraph..."
            renderBlock={({ children }) => <p className="mb-4">{children}</p>}
            allowedFeatures={[types.RichTextFeatures.Bold]}
          />
        </div>
      </div>
      <div className="flex-1 text-center">
        <Image imageClassName="mx-auto rounded" propName="image" alt="" />
        <div className="mt-6">
          <Text
            propName="subtitle"
            placeholder="Type a subtitle..."
            renderBlock={({ children }) => <h3 className="text-xl font-semibold mb-2">{children}</h3>}
          />

          <Text
            propName="address"
            placeholder="Type an address ..."
            multiline
            renderBlock={({ children }) => <p className="text-gray-600 dark:text-gray-300 text-sm">{children}</p>}
          />
        </div>
      </div>
    </Section>
  )
}

TextImage.schema = {
  name: blockNames.TextImage,
  label: 'TextImage',
  // category: '',
  // tags: [],

  // Defaults when a new brick is added

  getDefaultProps: () => ({
    title: 'CasaDalmine s.r.l.',
    paragraph: [
      {
        type: 'paragraph',
        children: [
          {
            text: "Casa Dalmine nasce da una profonda esperienza nel settore dell'intermediazione e locazione immobiliare.",
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'La conoscenza maturata in oltre 30 anni e la professionalità del team ci consentono di garantire un servizio di qualità.',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: "Poniamo grande attenzione ad ogni esigenza del cliente, gestendo con la massima trasparenza tutte le fasi della compravendita, dalla stima iniziale dell'immobile fino alla conclusione della vendita.",
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Forniamo inoltre assistenza per la soluzione di ogni eventuale problematica tecnica o fiscale.',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'CasaDalmine... Competenza... Continuità di risultati',
            bold: true,
          },
        ],
      },
    ],
    subtitle: 'Uffici di Casa Dalmine',
    address: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Via Manzoni, 55',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '24044 Dalmine (BG)',
          },
        ],
      },
    ],
  }),

  // Sidebar Edit controls for props
  sideEditProps: [sideBackgroundProps],
}

export default TextImage
