exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-app-settings-tsx": () => import("./../../../src/pages/admin/app-settings.tsx" /* webpackChunkName: "component---src-pages-admin-app-settings-tsx" */),
  "component---src-pages-admin-editor-tsx": () => import("./../../../src/pages/admin/editor.tsx" /* webpackChunkName: "component---src-pages-admin-editor-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-playground-tsx": () => import("./../../../src/pages/admin/playground.tsx" /* webpackChunkName: "component---src-pages-admin-playground-tsx" */),
  "component---src-pages-agenzia-old-tsx": () => import("./../../../src/pages/agenziaOld.tsx" /* webpackChunkName: "component---src-pages-agenzia-old-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-dettaglio-immobile-tsx": () => import("./../../../src/templates/dettaglio-immobile.tsx" /* webpackChunkName: "component---src-templates-dettaglio-immobile-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-slug-tsx": () => import("./../../../src/templates/slug.tsx" /* webpackChunkName: "component---src-templates-slug-tsx" */),
  "component---src-templates-valuta-immobile-tsx": () => import("./../../../src/templates/valutaImmobile.tsx" /* webpackChunkName: "component---src-templates-valuta-immobile-tsx" */)
}

