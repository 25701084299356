import React from 'react'
import { Text, types, RichText } from 'react-bricks/frontend'
import { blockNames } from '../../blockNames'

const ContattiItem: types.Brick = ({ ...rest }) => {
  return (
    <div className="mb-6" {...rest}>
      <Text
        renderBlock={({ children, attributes }) => (
          <h2 className="font-semibold" {...attributes}>
            {children}
          </h2>
        )}
        propName="contatto"
        placeholder="Contatto"
      />
      <RichText
        renderBlock={({ children, attributes }) => (
          <p className="text-gray-500 dark:text-gray-300" {...attributes}>
            {children}
          </p>
        )}
        propName="info"
        placeholder="Info"
        allowedFeatures={[types.RichTextFeatures.Link]}
        renderLink={(props) => (
          <a
            href={props.href}
            {...props.attributes}
            className="text-gray-500 hover:text-black dark:text-gray-300 dark:hover:text-white"
          >
            {props.children}
          </a>
        )}
      />
    </div>
  )
}

ContattiItem.schema = {
  name: blockNames.ContattiItem,
  label: 'Contatti item',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    info: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Via Manzoni, 55',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '24044 Dalmine (BG)',
          },
        ],
      },
    ],
    contatto: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Casa Dalmine s.r.l.',
          },
        ],
      },
    ],
  }),
}
export default ContattiItem
