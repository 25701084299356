import { Map, Marker } from 'pigeon-maps'
import { maptiler } from 'pigeon-maps/providers'
import React from 'react'
import { types } from 'react-bricks/frontend'
import { blockNames } from '../blockNames'

interface MapBrickProps {
  latitudine: number
  longitudine: number
  zoom: string
}

const MAPTILER_ACCESS_TOKEN = 'zGVHxdSZR3rlLBsL6hUv#0.5'
const MAP_ID = 'streets'

const mapTiler = maptiler(MAPTILER_ACCESS_TOKEN, MAP_ID)

export const MapBrick: types.Brick<MapBrickProps> = ({
  longitudine = 9.6057137,
  latitudine = 45.6521905,
  zoom = 16,
  ...rest
}) => {
  return (
    <div className="mx-auto max-w-4xl z-50" {...rest}>
      <Map
        center={[+latitudine, +longitudine]}
        height={300}
        metaWheelZoom
        zoom={+zoom}
        provider={mapTiler}
        dprs={[1, 2]}
        attribution={null}
        attributionPrefix={<></>}
      >
        <Marker anchor={[+latitudine, +longitudine]} />
      </Map>
    </div>
  )
}
MapBrick.schema = {
  name: blockNames.Map,
  label: 'Mappa',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    longitudine: 9.6057137,
    latitudine: 45.6521905,
    zoom: 16,
  }),
  sideEditProps: [
    {
      name: 'latitudine',
      label: 'Latitudine',
      type: types.SideEditPropType.Number,
    },
    {
      name: 'longitudine',
      label: 'Longitudine',
      type: types.SideEditPropType.Number,
    },
    {
      name: 'zoom',
      label: 'Zoom',
      type: types.SideEditPropType.Number,
    },
  ],
}

export default MapBrick
