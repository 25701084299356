import React from 'react'
import { BackgroundProps } from '../react-bricks/fixedSideEditProps'

interface SectionProps extends BackgroundProps {
  className?: string
  style?: React.CSSProperties
}
const Section: React.FC<SectionProps> = ({
  children,
  bg = { color: '#ffffff', className: 'bg-white' },
  className,
  style,
}) => {
  return (
    <section className={bg.className} style={style}>
      <div className={className}>{children}</div>
    </section>
  )
}

export default Section
