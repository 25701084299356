import React from 'react'
import { Text, types } from 'react-bricks/frontend'
import { BackgroundProps, sideBackgroundProps } from '../fixedSideEditProps'
import { blockNames } from '../blockNames'
import clsx from 'clsx'

import Section from '../../components/Section'

interface TitleProps extends BackgroundProps {
  titleBackground: 'image' | 'empty'
  imageBackground?: types.IImageSource
  parallasse: boolean
  textCenter: boolean
}

const Title: types.Brick<TitleProps> = ({ titleBackground, imageBackground, parallasse, textCenter, bg }) => {
  const style: React.CSSProperties = {}
  if (imageBackground && titleBackground === 'image') {
    style.backgroundImage = `url(${imageBackground.src})`
    style.backgroundRepeat = 'no-repeat'
    style.backgroundPosition = 'center'
    style.backgroundSize = 'cover'
  }
  if (parallasse) {
    style.backgroundAttachment = 'fixed'
  }
  return (
    <Section
      bg={bg}
      className={clsx(
        'relative isolate flex items-center justify-center',
        textCenter ? 'text-center' : '',
        titleBackground === 'image' ? 'h-44' : 'h-32'
      )}
      style={{
        ...style,
      }}
    >
      {titleBackground === 'image' && (
        <div
          className="absolute inset-0"
          style={{
            background: 'linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.4) 40%, transparent 100%)',
          }}
        />
      )}
      <div className="max-w-7xl mx-auto w-full px-6 z-10">
        <Text
          placeholder="Titolo"
          propName="title"
          renderBlock={({ children }) => (
            <h1 className={`text-4xl font-semibold ${titleBackground === 'image' ? 'text-white' : 'text-inherit'}`}>
              {children}
            </h1>
          )}
        />
      </div>
    </Section>
  )
}

Title.schema = {
  name: blockNames.Title,
  label: 'Title',
  getDefaultProps: () => ({
    title: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Titolo',
          },
        ],
      },
    ],
    textCenter: false,
    titleBackground: 'empty',
    bg: {
      color: '#ffffff',
      className: 'bg-white',
    },
  }),
  sideEditProps: [
    {
      groupName: 'Sfondo',
      defaultOpen: true,
      props: [
        {
          name: 'titleBackground',
          label: 'Sfondo titolo',
          type: types.SideEditPropType.Select,
          selectOptions: {
            display: types.OptionsDisplay.Radio,
            options: [
              {
                value: 'image',
                label: 'Con immagine',
              },
              {
                value: 'empty',
                label: 'Senza immagine',
              },
            ],
          },
        },
        {
          ...sideBackgroundProps,
          show: (props) => props.titleBackground === 'empty',
        },
        {
          name: 'imageBackground',
          label: 'Immagine di sfondo',
          type: types.SideEditPropType.Image,
          show: (props) => (props.titleBackground === 'image' ? true : false),
        },
        {
          name: 'parallasse',
          label: 'Parallasse',
          type: types.SideEditPropType.Boolean,
          show: (props) => (props.titleBackground === 'image' ? true : false),
        },
      ],
    },
    {
      name: 'textCenter',
      label: 'Titolo centrato',
      type: types.SideEditPropType.Boolean,
    },
  ],
}

export default Title
