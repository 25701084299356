export const blockNames = {
  Title: 'title',
  Contatti: 'contatti',
  ContattiItem: 'contatti-item',
  Map: 'map',
  Quote: 'quote',
  TextImage: 'text-image',
  ImageBlog: 'image-blog',
  BlogContent: 'blog-content',
  Thumbnails: 'thumbnails',
  Thumbnail: 'thumbnail',
}
