import React from 'react'
import { Text, RichText, Image, File, Repeater, types } from 'react-bricks/frontend'
import { FaQuoteLeft } from 'react-icons/fa'
import Section from '../../components/Section'
import { BackgroundProps, sideBackgroundProps } from '../fixedSideEditProps'
import { blockNames } from '../blockNames'

interface QuoteProps extends BackgroundProps {}

const Quote: types.Brick<QuoteProps> = ({ bg }) => {
  return (
    <Section className="max-w-xl mx-auto px-6 py-12 flex space-x-4" bg={bg}>
      <div>
        <FaQuoteLeft className="text-4xl text-red-700 dark:text-red-400" />
      </div>
      <div className="flex-1 mt-12 flex flex-col space-y-8">
        <Text
          propName="quote"
          placeholder="Quote..."
          renderBlock={({ children }) => (
            <p className="font-medium leading-relaxed text-xl text-stone-500 dark:text-stone-100">{children}</p>
          )}
        />

        <div className="flex space-x-1 items-center text-stone-600 dark:text-stone-200">
          {/* em dash options + shift + dash */}
          <span>—</span>
          <Text
            propName="author"
            placeholder="Type an author..."
            renderBlock={({ children }) => <span className="font-semibold leading-relaxed text-x">{children}</span>}
          />
        </div>
      </div>
    </Section>
  )
}

Quote.schema = {
  name: blockNames.Quote,
  label: 'Quote',
  // category: '',
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    author: 'Author',
    quote:
      'Chi è maestro nell’arte di vivere fa poca distinzione fra il proprio lavoro e il proprio gioco, la propria fatica e il proprio divertimento, il proprio studio e il proprio svago; quasi non sa quale sia dei due. Persegue semplicemente il proprio ideale di eccellenza in tutto quello che fa, lasciando agli altri decidere se stia lavorando o giocando. Ai suoi occhi, lui, sta facendo entrambi.',
  }),

  // Sidebar Edit controls for props
  sideEditProps: [sideBackgroundProps],
}

export default Quote
