import React from 'react'
import { Repeater, types } from 'react-bricks/frontend'
import Section from '../../../components/Section'
import { BackgroundProps, sideBackgroundProps } from '../../fixedSideEditProps'
import { blockNames } from '../../blockNames'
interface ContattiProps extends BackgroundProps {}
const Contatti: types.Brick<ContattiProps> = ({ bg }) => {
  return (
    <Section
      bg={bg}
      className="max-w-4xl mx-auto flex flex-col space-y-8 md:space-y-0 text-center md:text-left md:flex-row md:space-x-20 md:justify-between px-6 py-12"
    >
      <div className="md:flex-1">
        <Repeater propName="repeater-contatti" />
      </div>
      <div className="mb-12 md:mb-0 md:flex-[2] w-full">
        <Repeater propName="mappa" />
      </div>
    </Section>
  )
}

Contatti.schema = {
  name: blockNames.Contatti,
  label: 'Contatti',
  getDefaultProps: () => ({
    'repeater-contatti': [
      {
        info: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'Via Manzoni, 55',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: '24044 Dalmine (BG)',
              },
            ],
          },
        ],
        contatto: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'Casa Dalmine S.R.L.',
              },
            ],
          },
        ],
      },
      {
        info: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'Via Manzoni, 55',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: '24044 Dalmine (BG)',
              },
            ],
          },
        ],
        contatto: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'Casa Dalmine S.R.L.',
              },
            ],
          },
        ],
      },
      {
        info: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'Via Manzoni, 55',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: '24044 Dalmine (BG)',
              },
            ],
          },
        ],
        contatto: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'Casa Dalmine S.R.L.',
              },
            ],
          },
        ],
      },
    ],
    mappa: [
      {
        longitudine: 9.60599071450962,
        latitudine: 45.65643215600219,
      },
    ],
    bg: {
      color: '#ffffff',
      className: 'bg-white',
    },
  }),
  sideEditProps: [sideBackgroundProps],
  repeaterItems: [
    {
      min: 0,
      max: 3,
      itemType: blockNames.ContattiItem,
      itemLabel: 'Contatto',
      name: 'repeater-contatti',
    },
    {
      min: 0,
      max: 1,
      itemType: blockNames.Map,
      itemLabel: 'Mappa',
      name: 'mappa',
    },
  ],
}

export default Contatti
