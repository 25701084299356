import { types } from 'react-bricks/frontend'
import BlogTitle from './BlogContent'
import Contatti from './Contatti/Contatti'
import ContattiItem from './Contatti/ContattiItem'
import ImageBlog from './ImageBlog'
import MapBrick from './MapBrick'
import Quote from './Quote'
import TextImage from './TextImage'
import Thumbnail from './Thumbnail'
import Thumbnails from './Thumbnails'
import Title from './Title'

const bricks: types.Brick<any>[] = [
  Title,
  Contatti,
  ContattiItem,
  MapBrick,
  TextImage,
  Quote,
  BlogTitle,
  ImageBlog,
  Thumbnails,
  Thumbnail,
  // Put here your other bricks...
]

export default bricks
