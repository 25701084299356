import React from 'react'
import { Image, types } from 'react-bricks/frontend'
import Section from '../../components/Section'
import { blockNames } from '../blockNames'
import { BackgroundProps, sideBackgroundProps } from '../fixedSideEditProps'

interface ImageBlogProps extends BackgroundProps {}

const ImageBlog: types.Brick<ImageBlogProps> = ({ bg }) => {
  return (
    <Section bg={bg} className="max-w-2xl mx-auto pb-8">
      <Image propName="image" alt="altText" maxWidth={300} aspectRatio={1} noWrapper />
    </Section>
  )
}

ImageBlog.schema = {
  name: blockNames.ImageBlog,
  label: 'ImageBlog',
  // category: '',
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    title: 'Thick as a brick',
  }),

  // Sidebar Edit controls for props
  sideEditProps: [sideBackgroundProps],
}

export default ImageBlog
