import React from 'react'
import { ICONS } from '../react-bricks/fixedSideEditProps'

const IconPicker = ({ value, onChange }) => {
  return (
    <>
      <style>{`
        .gap-1 {
          gap: 0.25rem/* 4px */;
        }
        .px-1 {
          padding-left: 0.25rem/* 4px */;
          padding-right: 0.25rem/* 4px */;
        }
        .py-2 {
          padding-top: 0.5rem/* 8px */;
          padding-bottom: 0.5rem/* 8px */;
        }
        .text-white{
          color: rgb(255 255 255);
        }
        .grid {
          display: grid;
        }
        .grid-cols-5 {
          grid-template-columns: repeat(5, minmax(0, 1fr));
        }
        .rounded-lg {
          border-radius: 0.5rem/* 8px */;
        }
        .border-gray-400 {
          --tw-border-opacity: 1;
          border-color: rgb(156 163 175 / var(--tw-border-opacity));
        }
        .border {
          border-width: 1px;
        }
        .flex{
          display: flex;
        }
        .justify-center{
          justify-content: center;
        }
        .hover-bg-white:hover {
          --tw-bg-opacity: 1;
          background-color: rgb(255 255 255 / var(--tw-bg-opacity));
        }
        .cursor-pointer {
          cursor: pointer;
        }
        .mt-4 {
          margin-top: 1rem/* 16px */;
        }
        .text-lg {
          font-size: 1.125rem/* 18px */;
          line-height: 1.75rem/* 28px */;
        }
        `}</style>
      <div className="grid grid-cols-5 gap-1 mt-4">
        {ICONS.map(({ icon: Icon, code }) => {
          return (
            <div
              onClick={() => onChange(code)}
              className="px-1 py-2 border border-gray-400 rounded-lg flex justify-center hover-bg-white cursor-pointer text-lg"
              style={{ backgroundColor: value === code ? 'white' : 'inherit' }}
              key={code}
            >
              <Icon />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default IconPicker
