import React from 'react'
import { Link, RichText, types } from 'react-bricks/frontend'
import Section from '../../components/Section'
import { BackgroundProps, sideBackgroundProps } from '../fixedSideEditProps'
import { blockNames } from '../blockNames'

interface BlogContentProps extends BackgroundProps {}

const BlogContent: types.Brick<BlogContentProps> = ({ bg }) => {
  return (
    <Section bg={bg} className="max-w-2xl mx-auto px-6 py-12">
      <div className="text-slate-700 dark:text-slate-200 leading-relaxed tracking-wide">
        <RichText
          propName="paragraph"
          placeholder="Type a paragraph..."
          renderBlock={({ children }) => (
            <p className="text-slate-700 dark:text-slate-200 leading-relaxed tracking-wide min-h-[16px]">{children}</p>
          )}
          allowedFeatures={[
            types.RichTextFeatures.Bold,
            types.RichTextFeatures.Heading1,
            // types.RichTextFeatures.Heading2,
            // types.RichTextFeatures.Heading3,
            types.RichTextFeatures.Link,
            types.RichTextFeatures.UnorderedList,
          ]}
          renderH1={({ children }) => (
            <h1 className="text-2xl font-semibold text-slate-900 leading-normal tracking-normal">{children}</h1>
          )}
          // renderH2={({ children }) => <h2>{children}</h2>}
          // renderH3={({ children }) => <h3>{children}</h3>}
          renderUL={({ children }) => <ul className="list-disc">{children}</ul>}
          renderLI={({ children }) => <li className="list-inside">{children}</li>}
          renderLink={({ children, href }) => (
            <Link href={href} className="text-blue-600 underline" activeClassName="text-red-700">
              {children}
            </Link>
          )}
        />
      </div>
    </Section>
  )
}

BlogContent.schema = {
  name: blockNames.BlogContent,
  label: 'BlogContent',
  // category: '',
  // tags: [],

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    paragraph: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat quis justo sed laoreet. Morbi tristique congue mauris quis laoreet. Integer fringilla mauris non tempor dictum. Vestibulum ipsum turpis, suscipit eget pretium in, eleifend et nunc. ',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Nulla fermentum lorem vel ullamcorper laoreet. Aliquam sollicitudin mattis suscipit. ',
          },
        ],
      },
    ],
  }),

  // Sidebar Edit controls for props
  sideEditProps: [sideBackgroundProps],
}

export default BlogContent
