import clsx from 'clsx'
import * as React from 'react'
import { Image, RichText, Text, types } from 'react-bricks/frontend'
import IconPicker from '../../components/IconPicker'
import { blockNames } from '../blockNames'
import { ICONS } from '../fixedSideEditProps'

interface ThumbnailProps {
  imageOrIcon: 'image' | 'icon'
  icon?: string
}

const renderIcon = (iconCode: string) => {
  const Icon = ICONS.find((icon) => icon.code === iconCode).icon
  return <Icon className="w-10 h-10 text-white" />
}

const Thumbnail: types.Brick<ThumbnailProps> = ({ icon, imageOrIcon = 'image', ...rest }) => {
  return (
    <div className="flex flex-col px-8 mb-16 text-center items-center" {...rest}>
      {imageOrIcon === 'icon' && icon ? (
        <div
          className={clsx(
            'my-auto mb-5 rounded-full',
            imageOrIcon === 'icon'
              ? 'flex items-center justify-center bg-red-700 dark:bg-gray-700 dark:shadow-inner dark:shadow-gray-800 w-24 h-24'
              : 'overflow-hidden'
          )}
        >
          {renderIcon(icon)}
        </div>
      ) : (
        <Image
          propName="image"
          alt="feature"
          imageClassName={clsx('', imageOrIcon === 'icon' ? 'w-16 h-16 text-white' : 'h-32 w-32 object-cover')}
          containerClassName={clsx(
            'my-auto mb-5 rounded-full',
            imageOrIcon === 'icon' ? 'flex items-center justify-center bg-red-600 w-32 h-32' : 'border overflow-hidden'
          )}
        />
      )}

      <div className="flex-1">
        <Text
          propName="title"
          placeholder="title..."
          renderBlock={(props) => (
            <h3 className="text-lg text-gray-900 dark:text-gray-100 font-bold mb-3">{props.children}</h3>
          )}
        />
        <RichText
          propName="text"
          placeholder="text..."
          renderBlock={(props) => (
            <p className="text-gray-500 dark:text-gray-200 font-normal leading-6 text-base text">{props.children}</p>
          )}
        />
      </div>
    </div>
  )
}
Thumbnail.schema = {
  name: blockNames.Thumbnail,
  label: 'Thumbnail',
  hideFromAddMenu: true,
  playgroundLinkLabel: 'View source code on Github',
  playgroundLinkUrl: 'https://github.com/ReactBricks/react-bricks-ui/blob/master/src/website/Thumbnails/Thumbnail.tsx',

  getDefaultProps: () => ({
    imageOrIcon: 'image',
    title: 'Valutazione e perizie immobiliari',
    text: 'Vi offriamo una valutazione seria ed aggiornata all’attuale andamento del mercato immobiliare.',
    image: {
      src: 'https://images.reactbricks.com/original/193ab040-1fc7-47b5-8ca0-c2176be578a6.svg',
      placeholderSrc: 'https://images.reactbricks.com/original/193ab040-1fc7-47b5-8ca0-c2176be578a6.svg',
      srcSet: '',
    },
  }),
  sideEditProps: [
    {
      name: 'imageOrIcon',
      label: 'Immagine o icona',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          {
            value: 'image',
            label: 'Immagine',
          },
          {
            value: 'icon',
            label: 'Icona',
          },
        ],
      },
    },
    {
      name: 'icon',
      label: 'Icon Picker',
      type: types.SideEditPropType.Custom,
      show: (props) => (props.imageOrIcon === 'icon' ? true : false),
      component: ({ value, onChange }) => <IconPicker value={value} onChange={onChange} />,
    },
  ],
}

export default Thumbnail
