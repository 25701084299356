import React from 'react'
import { types } from 'react-bricks/frontend'
import {
  HiOutlineBadgeCheck,
  HiOutlineBell,
  HiOutlineBriefcase,
  HiOutlineCalendar,
  HiOutlineCamera,
  HiOutlineCash,
  HiOutlineChartPie,
  HiOutlineChatAlt,
  HiOutlineCheckCircle,
  HiOutlineClipboardList,
  HiOutlineClock,
  HiOutlineColorSwatch,
  HiOutlineCurrencyDollar,
  HiOutlineCurrencyEuro,
  HiOutlineDocumentText,
  HiOutlineEmojiHappy,
  HiOutlineGift,
  HiOutlineGlobe,
  HiOutlineHeart,
  HiOutlineHome,
  HiOutlineInformationCircle,
  HiOutlineKey,
  HiOutlineLightBulb,
  HiOutlineLocationMarker,
  HiOutlineMail,
  HiOutlineOfficeBuilding,
  HiOutlinePencil,
  HiOutlinePhone,
  HiOutlinePhotograph,
  HiOutlineScale,
  HiOutlineSearch,
  HiOutlineSpeakerphone,
  HiOutlineStar,
  HiOutlineTag,
  HiOutlineThumbUp,
  HiOutlineTruck,
  HiOutlineUserGroup,
  HiOutlineUsers,
  HiOutlineUser,
  HiOutlineAtSymbol,
} from 'react-icons/hi'
export type BackgroundProps = {
  bg: { color: string; className: string }
}

export const sideBackgroundProps: types.ISideEditProp = {
  name: 'bg',
  label: 'Background',
  type: types.SideEditPropType.Select,
  selectOptions: {
    display: types.OptionsDisplay.Color,
    options: [
      {
        value: { color: '#ffffff', className: 'bg-white text-gray-900 dark:bg-gray-900 dark:text-gray-100' },
        label: 'Bianco',
      },
      {
        value: { color: '#f9fafb', className: 'bg-gray-50 text-gray-900 dark:bg-gray-800 dark:text-gray-100' },
        label: 'Grigio',
      },
      {
        value: { color: '#de2e2d', className: 'bg-red-700 text-white dark:bg-red-900 dark:text-gray-100' },
        label: 'Rosso',
      },
      { value: { color: '#1e3a8a', className: 'bg-blue-900 text-white' }, label: 'Blu' },
    ],
  },
}

export const ICONS: { code: string; icon: React.FC<{ className?: string }> }[] = [
  { code: 'HiOutlineBadgeCheck ', icon: HiOutlineBadgeCheck },
  { code: 'HiOutlineBell ', icon: HiOutlineBell },
  { code: 'HiOutlineBriefcase ', icon: HiOutlineBriefcase },
  { code: 'HiOutlineCalendar ', icon: HiOutlineCalendar },
  { code: 'HiOutlineCamera ', icon: HiOutlineCamera },
  { code: 'HiOutlineCash ', icon: HiOutlineCash },
  { code: 'HiOutlineChartPie ', icon: HiOutlineChartPie },
  { code: 'HiOutlineChatAlt ', icon: HiOutlineChatAlt },
  { code: 'HiOutlineCheckCircle ', icon: HiOutlineCheckCircle },
  { code: 'HiOutlineClipboardList ', icon: HiOutlineClipboardList },
  { code: 'HiOutlineClock ', icon: HiOutlineClock },
  { code: 'HiOutlineColorSwatch ', icon: HiOutlineColorSwatch },
  { code: 'HiOutlineCurrencyDollar ', icon: HiOutlineCurrencyDollar },
  { code: 'HiOutlineCurrencyEuro ', icon: HiOutlineCurrencyEuro },
  { code: 'HiOutlineDocumentText ', icon: HiOutlineDocumentText },
  { code: 'HiOutlineEmojiHappy ', icon: HiOutlineEmojiHappy },
  { code: 'HiOutlineGift ', icon: HiOutlineGift },
  { code: 'HiOutlineGlobe ', icon: HiOutlineGlobe },
  { code: 'HiOutlineHeart ', icon: HiOutlineHeart },
  { code: 'HiOutlineHome ', icon: HiOutlineHome },
  { code: 'HiOutlineInformationCircle ', icon: HiOutlineInformationCircle },
  { code: 'HiOutlineKey ', icon: HiOutlineKey },
  { code: 'HiOutlineLightBulb ', icon: HiOutlineLightBulb },
  { code: 'HiOutlineLocationMarker ', icon: HiOutlineLocationMarker },
  { code: 'HiOutlineMail ', icon: HiOutlineMail },
  { code: 'HiOutlineOfficeBuilding ', icon: HiOutlineOfficeBuilding },
  { code: 'HiOutlinePencil ', icon: HiOutlinePencil },
  { code: 'HiOutlinePhone ', icon: HiOutlinePhone },
  { code: 'HiOutlinePhotograph ', icon: HiOutlinePhotograph },
  { code: 'HiOutlineScale ', icon: HiOutlineScale },
  { code: 'HiOutlineSearch ', icon: HiOutlineSearch },
  { code: 'HiOutlineSpeakerphone ', icon: HiOutlineSpeakerphone },
  { code: 'HiOutlineStar ', icon: HiOutlineStar },
  { code: 'HiOutlineTag ', icon: HiOutlineTag },
  { code: 'HiOutlineThumbUp ', icon: HiOutlineThumbUp },
  { code: 'HiOutlineTruck ', icon: HiOutlineTruck },
  { code: 'HiOutlineUserGroup ', icon: HiOutlineUserGroup },
  { code: 'HiOutlineUsers ', icon: HiOutlineUsers },
  { code: 'HiOutlineUser ', icon: HiOutlineUser },
  { code: 'HiOutlineAtSymbol ', icon: HiOutlineAtSymbol },
]
